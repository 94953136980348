import axios from 'axios';
import { TRPMEvent } from 'components/main/rpm-event/rpm.types';
import AuthService from 'services/auth-service';
import { RpmEventData } from './providerTimeTracker.types';

const contentType = 'application/json';

export enum RpmProviderEvent {
  ProviderViewMemberProfile = 'provider-view-member-profile',
  ProviderVisitMemberProfile = 'provider-visit-member-profile',
  ProviderSendPrivateMessageToMember = 'provider-send-private-message-to-member',
  ProviderSendPrivateMessageToProvider = 'provider-send-private-message-to-provider',
  ProviderReviewPicture = 'provider-review-picture',
  ProviderSendCard = 'provider-send-card',
  ProviderSendCardComment = 'provider-send-card-comment',
  ProviderSendFoodComment = 'provider-send-food-comment',

  // this event is created dynamically with the tab name from the variable GRAPH_TABS and could
  // be one of this weights, heart_rate, glucose, steps, blood_pressure, card, exercises, vitals, notes
  ProviderViewProfileChart = 'provider-view-profile-chart-',
  ProviderProfileAddWeight = 'provider-profile-add-weight',
}

export class ProviderTimeTrackerClient {
  auth = AuthService.getAuth();

  providerId = this.auth?.Authorization.id;

  customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BILLING_BASE_URL,
    headers: {
      Accept: `${contentType}`,
      'Content-Type': `${contentType}`,
      Authorization: `Bearer ${this.auth?.Authorization.token}`,
    },
  });

  track = async (data: RpmEventData) => {
    await this.customAxios.post<{ data: string }>(`/events`, data);
    return data;
  };

  list = async ({ patientUuid }: { patientUuid: string; limit?: number; offset?: number }) => {
    // TODO: send pagination elements when server is working correctly
    const url = `/events/${patientUuid}/patient`;
    const response = await this.customAxios.get<{
      events: {
        memberUuid: string;
        providerUuid: string;
        eventType: string;
        time: string;
      }[];
    }>(url);

    const dataReturn: TRPMEvent = {
      data: response.data.events,
      total: response.data.events.length,
    };

    return dataReturn;
  };
}
