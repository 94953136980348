import axios, { AxiosInstance } from 'axios';
import {
  ConditionStatus,
  ConditionWithRelations,
  FindAllConditionsParams,
  FindAllConditionsResponse,
} from '../interfaces/health-data';
import AuthService from './auth-service';

const HEALTH_DATA_BASE_URL = process.env.REACT_APP_PUBLIC_HEALTH_DATA_BASE_URL;

class HealthDataService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: HEALTH_DATA_BASE_URL,
      headers: {
        Authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
      },
    });
  }

  public async findAllConditions(
    params: FindAllConditionsParams
  ): Promise<FindAllConditionsResponse | null> {
    try {
      const { conditions, pagination } = (
        await this.axios.get<FindAllConditionsResponse>(`/medical-setup/conditions`, {
          params,
        })
      ).data;

      return {
        conditions,
        pagination,
      };
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  filterValidConditions(
    conditions: ConditionWithRelations[],
    oldConditionCodes: string[] | null
  ): ConditionWithRelations[] {
    return conditions.filter((condition) => {
      return (
        condition.condition.status === ConditionStatus.ACTIVE ||
        (oldConditionCodes && oldConditionCodes.includes(condition.condition.icd10Code))
      );
    });
  }
}

const healthDataService = new HealthDataService();

export default healthDataService;
