import { Close, Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { getFormattedDateFromISOString } from 'helpers/date';
import { MedicalProblemCode } from 'node-api/scribe/medical';
import {
  Laboratory,
  MedicalProblemsContentType,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe.types';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import healthDataService from 'services/health-data';
import '../../css/note-edition.css';
import { CustomTable } from '../CustomTable';
import { SectionProps } from './render-sections';

export type ProblemOption = { label: string; value: MedicalProblemCode };

export const MedicalProblemSection = ({
  allConditions,
  content,
  stage,
  handlers,
}: SectionProps<MedicalProblemsContentType>) => {
  const [openPopover, setOpenPopover] = useState<{ [icd10Code: string]: boolean }>({});
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [selectedProblem, setSelectedProblem] = useState<ProblemOption | null>(null);
  const [showAutocompleteError, setShowAutocompleteError] = useState<boolean>(false);
  const [medicalProblemOptions, setMedicalProblemOptions] = useState<ProblemOption[]>([]);

  const conditions = useMemo(
    () =>
      healthDataService.filterValidConditions(
        allConditions,
        content.data.map((problem) => problem.icd10Code)
      ),
    [allConditions, content.data]
  );

  const handleOpenPopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const handleClosePopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const laboratoryColumns: GridColDef[] = [
    {
      field: 'observation',
      headerName: 'Observation',
      flex: 1,
      minWidth: 150,
      editable: true,
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      minWidth: 150,
      editable: true,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'resultDate',
      headerName: 'Result Date',
      flex: 1,
      minWidth: 150,
      renderCell: (row) => getFormattedDateFromISOString(row.value, 'YYYY-MM-DD'),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
  ];

  useEffect(() => {
    setOpenPopover(
      conditions.reduce(
        (pv, cond) => ({ ...pv, [cond.condition.icd10Code]: false }),
        {} as { [code: string]: boolean }
      )
    );

    setMedicalProblemOptions(
      conditions.map(({ condition }) => ({
        label: condition.name,
        value: condition.icd10Code,
      }))
    );
  }, []);

  // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section

  /*
const examsColumns: GridColDef[] = [
    {
      field: 'procedure',
      headerName: 'Procedure',
      flex: 1,
      minWidth: 250,
      type: 'string',
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 50,
      renderCell: (row) => getFormattedDateFromISOString(row.value, 'YYYY-MM-DD'),
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
  ];
  */

  return (
    <Box className='edit-section' mb={3} display='flex' flexDirection='column'>
      <Typography fontWeight='600' mb={1}>
        Problems
      </Typography>

      <Box>
        {content.data.map((medicalProblem, index) => {
          const {
            plan,
            history,
            dataReview,
            subjectiveUpdates,
            name,
            icd10Code,
            assessmentAndDiscussion,
          } = medicalProblem ?? {};

          if (!content.sectionId || !medicalProblem) {
            return [];
          }

          return (
            <Box>
              <Accordion className='note-edition-accordion'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography fontWeight='600'>{`${icd10Code}:`}</Typography>
                    <Typography fontWeight='400'>&nbsp;{`${name}`}</Typography>
                  </Box>
                  <Popover
                    open={openPopover?.[icd10Code]}
                    key={`${name}-${index}`}
                    anchorEl={anchorEl as Element}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Typography sx={{ p: 2 }}> Are you sure you want to delete it?</Typography>

                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      paddingBottom={3}>
                      <Button
                        className='medical-problem-section-button cancel-problem-button'
                        startIcon={<Close color='error' />}
                        variant='outlined'
                        onClick={(e) => handleOpenPopover(e, icd10Code)}>
                        Cancel
                      </Button>

                      <Button
                        className='medical-problem-section-button remove-problem-button'
                        key={`remove-button-${index}`}
                        startIcon={<Delete />}
                        variant='contained'
                        onClick={(e) => {
                          handleClosePopover(e, icd10Code);

                          handlers.handleDeleteProblem(index);
                        }}>
                        Remove
                      </Button>
                    </Box>
                  </Popover>

                  {content.data.length > 1 && (
                    <Button
                      className='medical-problem-section-button delete-problem-button'
                      key={`delete-problem-button-${index}`}
                      startIcon={<Close color='error' />}
                      onClick={(e) => handleOpenPopover(e, icd10Code)}>
                      Delete
                    </Button>
                  )}
                </AccordionSummary>

                <Box mb={2}>
                  <Typography mt={2} mb={2}>
                    History:
                  </Typography>
                  <TextField
                    multiline
                    value={history}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          history: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2} className='note-edition-data-review-table'>
                  <Typography mb={2}>Data Review:</Typography>
                  <Box>
                    <Typography mb={2} mt={2} fontWeight={600}>
                      Laboratories
                    </Typography>

                    <CustomTable
                      columns={laboratoryColumns}
                      data={dataReview?.labs || []}
                      onChange={(rows) => {
                        handlers.handleChangeMedicalProblem({
                          ...content,
                          data: {
                            ...medicalProblem,
                            dataReview: {
                              ...medicalProblem.dataReview,
                              labs: rows as Laboratory[],
                            },
                          },
                          index,
                        });
                      }}
                    />
                  </Box>

                  {/*
                  // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section
                  <Box>
                    <Typography mb={2} mt={2} fontWeight={600}>
                      Exams
                    </Typography>

                    <CustomTable columns={examsColumns} data={dataReview?.exams || []} />
                  </Box> */}
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Subjective Updates:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={subjectiveUpdates}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          subjectiveUpdates: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Assessment and Discussion:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={assessmentAndDiscussion}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          assessmentAndDiscussion: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Plan:</Typography>
                  <TextField
                    disabled={stage === MeetingSummaryStage.PreVisit}
                    multiline
                    value={plan}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          plan: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>
              </Accordion>
            </Box>
          );
        })}
      </Box>

      <Box className='note-edition-add-problem'>
        <Autocomplete
          className='selector'
          value={selectedProblem}
          size='small'
          fullWidth
          getOptionDisabled={(option) => !!content.data.find((p) => p.name === option.label)}
          disablePortal
          onChange={(_: unknown, newValue: { label: string; value: string } | null) => {
            const problem = newValue as ProblemOption;

            setShowAutocompleteError(false);
            setSelectedProblem(problem);

            if (!!problem) {
              handlers.handleAddProblem(problem);
            }
          }}
          options={medicalProblemOptions}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box key={key} component='li' {...optionProps}>
                <strong>{option.value}:</strong>&nbsp;{option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Add new problem'
              sx={{ height: '100%' }}
              error={showAutocompleteError}
              helperText={showAutocompleteError ? 'Please select a problem to add it' : ''}
            />
          )}
        />
      </Box>
    </Box>
  );
};
